import React from "react";

//import Zindagi from "assets/images/bg/Zindagi.jpg";
//import lakshmi from "assets/images/bg/Lakshmi.jpg";

import classes from "./AboutUs.module.css";

function AboutUs() {
  return (
    <div className="bg-white" style={{ textAlign: "justify" }}>
      <div className={classes.centerHeading}>
        <h3 className={`text-center py-3`}>About Us</h3>
      </div>

      <div className="container text-black  px-4 pb-5 textJustify">
        DEAR Lottery is the leading player in the lottery industry of India,
        with a turnover of over 2 Billion USD. DEAR Lottery has developed a vast
        network of dealers, stockists and agents across different
        lottery-playing states in India, wherever lottery sale is permissible.
        It has retained its leadership position in the business through
        innovations and continual market research within the field of lotteries.
        Since its inception in 1991 DEAR Lottery has been growing at a fast pace
        in the distribution of traditional paper lotteries of various State
        Governments.
        <div className={classes.ImgaeContainer}>
          <img src="assets/images/bg/Lakshmi.jpg" alt="lakshmi" />
        </div>
        This has been possible due to the company's acumen for competitive
        bidding, aggressive marketing and efficient lottery operations across
        many States in India, with an energetic attitude towards growth. DEAR
        Lottery was the first lottery company in India to facilitate the Live
        telecast of the draws on TV, conducted by various governments, for
        lotteries distributed through them. This brought in more trust,
        credibility and transparency to the trade and the company's reputation
        also increased manifold.DEAR Lottery is known for offering best schemes
        for players and committed to quality and efficiency and have an
        outstanding execution, strong relationships and market presence in the
        Indian lottery market. The company's growth is powered by a strong team
        of 1000 plus professionals working in different areas of lottery
        operations and marketing.
        <div className={classes.ImgaeContainer}>
          <img src="assets/images/bg/Zindagi.jpg" alt="Zindagi" />
        </div>
        DEAR Lottery has made considerable investment in technology and has
        built a team of 50-plus software engineers and technology professionals
        in its gaming technology division. This strong and committed team has
        positioned and consolidated DEAR Lottery as the number one Lottery
        Company in India. DEAR Lottery is a member of Asia Pacific Lottery
        Association (APLA). Since 2001 DEAR Lottery has been a member of the
        World Lottery Association (WLA). In 2009, WLA had granted accreditation
        to DEAR Lottery for meeting the criteria for achieving the Level 1 of
        the WLA Responsible Gaming Framework. The credibility and trust that the
        millions of lottery playing public reposed in DEAR Lottery is
        unrivalled.
      </div>
    </div>
  );
}

export default AboutUs;
