import React, { Component } from 'react';
import { FeatureLeft, FeatureRight } from './Features/index';

class Features extends Component {
    render() {
        return (
        <section className="book-preview section-padding dark-bg-light pb-0">
            
            <div className="container mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="section-heading center-heading text-center mb-60">
                            <h3 className="heading-title text-white mb-4">How to Play Our Dear Goverment Lottery App</h3>
                          
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <FeatureLeft />
                    </div>

                    <div className="col-lg-6">
                        <FeatureRight />
                    </div>
                </div>
            </div>
        </section> 
        );
    }
}

export default Features;
