
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Feature3 extends Component {
    constructor(props){
        super(props);
        this.state = {
           features: [
                {
                    id: 1,
                    img: './assets/images/banner/Card_011.png',
                    alt: 'images',
                    icon: 'bi bi-1-circle',
                   
                  
                },
                {
                    id: 2,
                    img: './assets/images/banner/Card_02.png',
                    alt: 'images',
                    icon: 'bi bi-2-circle',
                  
                   
                },
                {
                    id: 3,
                    img: './assets/images/banner/Card_03.png',
                    alt: 'images',
                    icon: 'bi bi-3-circle',
                    // title: 'Dear Tuesday Evening',
                   
                }
            ]
        }
    }

    render() {
        return (
            <section className="feature-2 section-padding dark-bg-light">
                <div className="container">
                    
                    <div className="row mb-4">
                        {
                            this.state.features.map((data,index) => (
                                <div className="col-lg-4 col-md-6">
                                     <Link to="https://play.google.com/store/games?hl=en_IN&gl=US">
                                    <div className="feature-style-2 mb-4 mb-lg-0" key={data.id}>
                                    <img src={data.img} alt={data.alt} className="img-fluid"/>
                                        <i className={data.icon}></i>
                                        <div className="feature-text">
                                            {/* <h4>{data.title}</h4> */}
                                         
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            
                            ))
                        }
                        
                    </div>
                    <div className="row mb-4">
                        {
                            this.state.features.map((data,index) => (
                                <div className="col-lg-4 col-md-6">
                                     <Link to="https://play.google.com/store/games?hl=en_IN&gl=US">
                                    <div className="feature-style-2 mb-4 mb-lg-0" key={data.id}>
                                    <img src={data.img} alt={data.alt} className="img-fluid"/>
                                        <i className={data.icon}></i>
                                        <div className="feature-text">
                                            <h4>{data.title}</h4>
                                         
                                        </div>
                                    </div>
                                    </Link>
                                </div>
                            
                            ))
                        }
                        
                    </div>
                </div>
            </section> 
        );
    }
}

export default Feature3;
