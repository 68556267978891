import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: null
        };
    }

    handleSelect = (activeKey) => {
        this.setState({ activeKey });
    }

    render() {
        return (
            <section className="faq section-padding dark-bg-light mag-25">
                <div className="container">
                    <div className="row justify-content-center mb-3">
                        <div className="col-lg-8">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Frequently Asked Questions</h3>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <Accordion activeKey={this.state.activeKey} onSelect={this.handleSelect} className="panel-group">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>Can I trust the randomness of the draws?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        Yes, you can trust the randomness of our draws. We use rigorously tested and certified random number generation algorithms to ensure fair and unbiased results.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>What types of Lottery are available on the app?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        We offer a variety of Lottery, including daily, bi-weekly, weekly, bi-monthly, and monthly draws, providing users with frequent opportunities to participate and win.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>How do I purchase tickets?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        To purchase tickets, simply navigate to the apps home page or Lottery list, choose your preferred Lottery, select your numbers, and complete the payment process using the available balance in the wallet. Your tickets will be instantly confirmed and entered into the upcoming draw.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>What happens if I win?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        If you win, you will be notified promptly through the app, and winnings will be credited to your wallet. You can then choose to withdraw or use the winnings to participate in future draws.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>

                        <div className="col-lg-6">
                            <Accordion activeKey={this.state.activeKey} onSelect={this.handleSelect} className="panel-group">
                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>Are there any fees or commissions?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        No, there are no commissions. We do not charge any fees on your winnings, ensuring that you receive the full amount you've won without any deductions.
                                    </Accordion.Body>
                                </Accordion.Item> */}

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>What happens if I forget my numbers?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        If you forget your numbers, don't worry. The purchased ticket details are securely stored in the 'My Tickets' tab, allowing you to retrieve and check your numbers at any time.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>How can I check Lottery results?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        You can easily check Lottery results in the 'My Tickets' tab, where the outcomes of the draws are promptly updated, ensuring convenient access to the latest results for your purchased tickets.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                    <Accordion.Header><i className="more-less fa fa-plus"></i>What are the age restrictions for using the app?</Accordion.Header>
                                    <Accordion.Body className="panel-body" >
                                        The minimum age requirement for using the app is 21+. Users must be at least 21 years old to participate in Lottery activities and ensure compliance with legal regulations.
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Faq;
