import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Author extends Component {
    constructor(props){
        super(props);
        this.state = {
          
        }
    }
    render() {
        return (
            <section className="author-section section-padding dark-bg-light mag-25">
                 <div className="container">
                    <div className="row justify-content-center mb-2">
                        <div className="col-lg-6">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="heading-title">Ticket to a Better Life</h3>
                             
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center px-sm-2">
                       
                        <div className="col-lg-12 col-md-12">
                            <div className="section-heading mb-0 p-2">
                             
                                <p>Dear Goverment Lottery is the leading player in the Lottery industry of India, with a turnover of over 2 Billion USD. Dear Goverment Lottery has developed a vast network of dealers, stockists and agents across different Lottery-playing states in India, wherever Lottery sale is permissible. 
                                    It has retained its leadership position in the business through innovations and continual market research within the field of lottery.</p>
                                <p>Since its inception in 1991  Dear Goverment Lottery has been growing at a fast pace in the distribution of traditional paper lottery of various State Governments. This has been possible due to the company's acumen for competitive bidding,
                                     aggressive marketing and efficient Dear Goverment Lottery operations across many States in India, with an energetic attitude towards growth.</p>
                                     <p>Dear Goverment Lottery was the first Lottery company in India to facilitate the Live telecast of the draws on TV, 
                                        conducted by various governments, for lottery distributed through them. This brought in more trust, credibility and transparency to the trade and the company's reputation also increased manifold. Dear Goverment Lottery is known for offering best schemes for players and committed to quality and efficiency and have an outstanding execution, strong relationships and market presence in the Indian Lottery market.
</p>
<p>The company's growth is powered by a strong team of 1000 plus professionals working in different areas of Lottery operations and marketing. 
Dear Goverment Lottery has made considerable investment in technology and has built a team of 50-plus software engineers and technology professionals in its
    gaming technology division. This strong and committed team has positioned and consolidated Dear Goverment Lottery as the number one Lottery Company in India.
</p>
<p>
Dear Goverment Lottery is a member of Asia Pacific Lottery Association (APLA). Since 2001 Dear Goverment Lottery has been a member of the World Lottery Association (WLA).
 In 2009, WLA had granted accreditation to Dear Goverment Lottery for meeting the criteria for achieving the Level 1 of the WLA Responsible Gaming Framework.
  The credibility and trust that the millions of Lottery playing public reposed in Dear Goverment Lottery is unrivalled.</p>
                            </div>

                            {/* <div className="author-desc">
                               
                                <p>Follow Us <Link to="https://www.instagram.com/Dearlotteries/">Instagram</Link> or <Link to="https://www.facebook.com/Dearlotteries?_rdr">Facebook</Link> or <Link to="https://mobile.twitter.com/Dearlottery1">Twitter</Link></p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section> 
        );
    }
}

export default Author;
