import React from 'react'
import { Header, Feature2, Client, Footer } from '../layouts/home04/index';

function Tickets() {
  return (
    <div className="dark-theme">
    <Header />
    <Feature2 />
    <div className='section-padding'></div>
    <Client />
    <Footer className="pb-200" />
</div>
  )
}

export default Tickets