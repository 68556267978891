import React, { Component, createRef } from 'react';

class Intro extends Component {
    constructor(props) {
        super(props);
        this.videoRef = createRef();
    }

    handleMouseEnter = () => {
        if (this.videoRef.current) {
            this.videoRef.current.play();
        }
    }

    handleMouseLeave = () => {
        if (this.videoRef.current) {
            this.videoRef.current.play();
        }
    }

    render() {
        return (
            <section>
                <section className="cta-2">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="video-container">
                                <video
                                    ref={this.videoRef}
                                    muted
                                    loop
                                    autoPlay
                                    playsInline
                                    onMouseEnter={this.handleMouseEnter}
                                    onMouseLeave={this.handleMouseLeave}
                                    className=""
                                >
                                    <source src={process.env.REACT_APP_HOME_PAGE_VIDEO} type="video/mp4" />
                                </video>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

export default Intro;
