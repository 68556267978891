import React, { Component, createRef } from 'react';

class Intro extends Component {
  constructor(props) {
    super(props);
    this.videoRef = createRef();
  }

  handleMouseEnter = () => {
    if (this.videoRef.current) {
      this.videoRef.current.muted = false; // Unmute the video
      this.videoRef.current.play(); // Play the video
    }
  };

  handleMouseLeave = () => {
    if (this.videoRef.current) {
      this.videoRef.current.muted = true; // Mute the video
      this.videoRef.current.pause(); // Pause the video
    }
  };

  render() {
    return (
      <section className="pt-100 center-about dark-bg">
        <div className="container">
          <div className="row justify-content-center pb-2">
            <div className="col-lg-8">
            <div className="section-heading center-heading text-center mb-60">
                            <h3 className="heading-title text-white">Watch and Win</h3>
                          
              </div>
          </div>


          
          </div>
        </div>

        <section className="cta-2">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div
                  style={{ position: 'relative', paddingBottom: '56.25%', height: 0, marginBottom: '20px' }}
                  onMouseEnter={this.handleMouseEnter}
                  onMouseLeave={this.handleMouseLeave}
                >
                  <video
                    ref={this.videoRef}
                    controls
                    loop
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover'
                    }}
                    className=""
                  >
                    <source src={process.env.REACT_APP_HOMEPAGE_VIDEO} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Intro;
