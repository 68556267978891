import React, { Component } from 'react';


class Footer extends Component {
    render() {
        return (          
            <section className="footer pt-120 dark-bg">
                <div className="container coprsize">
                    <div className="row">
                        <div className="col-lg-4 mx-auto col-sm-6 col-md-12">
                            <div className="widget footer-widget mb-1 mb-lg-0">
                                <img src="assets/images/logo.png" alt="DearLottery" className="img-fluid" style={{ width: '190px' }} />
                                <p className="mt-3">Pursuant to Section 135 of the Companies Act, 2013 (hereinafter referred to as "the said act")</p>
                            </div>
                        </div>

                        <div className="col-lg-4 mx-auto col-sm-6 col-md-12">
                            <div className="widget footer-widget mb-1 mb-lg-0">
                                <ul className="list-unstyled footer-links">
                                    <h4 className='mb-2'> Quick Links </h4>
                                    <a href="/PrivacyPolicy" target='_blank'>Privacy Policy</a><br></br>
                                   <a  href="/RefundPolicy" target='_blank'>Return Policy</a><br></br>
                                   <a  href="/TermsandConditions" target='_blank'>Terms Conditions</a><br></br>
                                 
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="widget footer-widget mb-1 mb-lg-0">
                                <h4> Corporate Address </h4>
                                <p>169, Habibullah Road, Bharathy Nagar, <br></br>
                                T.Nagar, Chennai, Tamil Nadu - 600017, India</p>
                                <p>Mailto:info@charles-technologies.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright">
                                    <p>© Copyrights 2024 by Charles Technologies Private Limited. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div className="fixed-btm-top">
                    <a href="#top-header" className="js-scroll-trigger scroll-to-top"><i className="fa fa-angle-up"></i></a>
                </div>
            </section>
        );
    }
}

export default Footer;
