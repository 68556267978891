import React from 'react'
import { Header, Contact, Client, Footer } from '../layouts/home04/index';


function RefundPolicy() {
  return (
    <div className="dark-theme">       
    <Header />  
   
    <section className="section-padding contact dark-bg-light main-content pb-200">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-8">
                    <div className="section-heading center-heading text-center mb-60">
                        <h3 className="heading-title">REFUND POLICY</h3>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-lg-10 col-sm-12 col-md-12">
                    <ul>
                        <li>
                            REFUND POLICY
                            Online lotteries purchased from our platform are not eligible for refund. Once
                            the tickets are booked and the payment is made, refunds cannot be entertained.
                            Players are requested to exercise caution while booking tickets.
                            You will not be entitled for refunds if you have been earlier banned or blocked
                            by us or if you have violated our policies/ terms and conditions. In addition, you
                            may not be entitled for refunds, if Dear Lotteries determines that you are
                            abusing the refund policy.
                        </li>
                        <h6>When can I seek a refund? </h6>
                        <li>
                            Only in the case of payment being successful but ticket booking has become
                            unsuccessful the refund is eligible. It is explained in the following
                            circumstances:
                        </li>
                        <li>
                            (a) You are unable to book the ticket(s) for any technical reason or otherwise,
                            but You have successfully made the payment (through any online payment
                            systems, net banking, UPIs, credit or debit cards) and the payment is credited to
                            our account/ payment service provider.
                        </li>
                        <li>
                            (b) You are unable to book the ticket(s) for any technical reason or otherwise,
                            but You have successfully made the payment from your wallet and the payment
                            are credited to our account/ payment service provider.
                            Normally, refunds are issued to the original payment method used for the
                            purchase, directly by us or through our payment service provider. Alternatively,
                            Dear Lottery may on its sole option decide to issue the refund using a different
                            method. We endeavour to refund the amount within 7 days’ time from the date
                            of your request. But please be aware that the processing time for refunds varies
                            depending on payment method.</li>
                            <li>
                            You have to submit proof of payment (including any payment reference
                            number) and upon successful verification of the transaction, refunds will be
                            processed/will be considered by us.
                        </li>
                        <li>
                            For refunds and any questions on refunds, please write to
                            <a href="mailto:support@dearlotteries.app"> support@dearlotteries.app.</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    
    <Footer className="footer" />
</div>
  )
}

export default RefundPolicy
