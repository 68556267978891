import React, { Component } from "react";

class Feature2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
        {
          id: 1,
          img: "./assets/images/banner/DearTuesdayMorning.png",
          alt: "images",
          icon: "bi bi-1-circle",
        },
        {
          id: 2,
          img: "./assets/images/banner/DearTuesdayAfternoon.png",
          alt: "images",
          icon: "bi bi-2-circle",
        },
        {
          id: 3,
          img: "./assets/images/banner/DearTuesdayEvening.png",
          alt: "images",
          icon: "bi bi-3-circle",
          // title: 'Dear Tuesday Evening',
        },
      ],
    };
  }

  render() {
    return (
      <section className="feature-2  section-padding dark-bg-light mag-25">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading center-heading text-center mb-60">
                <h3 className="heading-title text-white">Popular Lotteries </h3>
                <p className="playlotteries lactcenter">
                  Play Dear Government Lottery and win big!
                </p>
              </div>
            </div>
          </div>
          <div className="row scroll-container">
            {this.state.features.map((data, index) => (
              <div className="col-lg-4 col-md-6" key={data.id}>
                <div className="feature-style-2 mb-4 mb-lg-0">
                  <img src={data.img} alt={data.alt} className="img-fluid" />
                  <i className={data.icon}></i>
                  <div className="feature-text"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

export default Feature2;
