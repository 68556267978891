import React from "react";
import { Header, Features, Footer } from "../layouts/home04/index";

function Appfeatures() {
  return (
    <div className="dark-theme">
      <Header />
      <Features />
      <Footer />
    </div>
  );
}

export default Appfeatures;
