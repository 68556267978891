import React, { Component } from 'react';
import Slider from "react-slick";


class Review extends Component {

    constructor(props){
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    img: './assets/images/banner/GoaLogo-01.png',
                    alt: 'Goa Logo',
                },
                {
                    id: 2,
                    img: './assets/images/banner/NagalandLogo-01.png',
                    alt: 'Nagaland Logo',
                },
                {
                    id: 3,
                    img: './assets/images/banner/PunjabLogo-01.png',
                    alt: 'Punjab Logo',
                },
                {
                    id: 4,
                    img: './assets/images/banner/SikkimLogo-01.png',
                    alt: 'Sikkim Logo',
                },
                {
                    id: 5,
                    img: './assets/images/banner/Arunachal_Pradesh_Seal.svg',
                    alt: 'Arunachal Pradesh Seal',
                },
            ]
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            centerMode: true,
            arrows: false,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerPadding: '10px',
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                },
                {
                    breakpoint: 900,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 1
                    }
                  },
              
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return (
            <section className="testimonial dark-bg">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-heading center-heading text-center mb-60">
                                <h3 className="playlotteriors heading-title">Our Products</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="testimonials-slides">
                                <Slider {...settings} style={{ padding: '10px' }}>
                                    {
                                        this.state.testimonial.map((data) => (
                                            <div key={data.id}>
                                                <div className="client-desc d-flex slick-lists">
                                                    <div className="clientlogo">
                                                        <img src={data.img} alt={data.alt} className=" img-fluid"/>
                                                    </div>
                                                </div>
                                            </div> 
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Review;
