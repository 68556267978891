import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Howtoplay extends Component {
    render() {
        return (
        <section className="book-preview section-padding dark-bg-light pb-0">
            <div className="container">
           
                <div className="row justify-content-center pb-2">
                    <div className="col-lg-12 mb-2">
                        <div className="section-heading center-heading text-center mb-60">
                            <h3 className="heading-title text-white">How to Play Our Dear Goverment Lottery App</h3>
                          
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                    <div className="topic-item">
                            <div className="icon-box">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="topic-content">
                                <h4>Introduction :</h4>
                                <p style={{ marginBottom: '0rem' }}>Welcome to the Dear Government Lottery App, your gateway to an enhanced lottery experience! Your chance to win amazing prizes is just a tap away. Follow our easy steps to participate and prepare for the exhilarating draw. Good luck!
                                </p>
                            </div>
                        </div>
                        <div className="topic-item">
                            <div className="icon-box">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="topic-content">
                                <h4>Explore Ongoing Dear Government Lotteries :</h4>
                                <p style={{ marginBottom: '0rem' }}>Navigate through Dear Government Lottery app to access a range of available lotteries, each with unique features such as ticket availability, pricing, and prize information to enhance your experience and increase your chances of winning.
                                </p>
                            </div>
                        </div>
                        {/* <div className="topic-item">
                            <div className="icon-box">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="topic-content">
                                <h4>Choose YourLotteries:</h4>
                                <p>Select theLotteries you want to participate in.Review the details provided by the administrator, including the total number of tickets available, ticket prices, and the winnings %

</p>
                            </div>
                        </div> */}
                    </div>

                    <div className="col-lg-6">
                    <div className="topic-item">
                            <div className="icon-box">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="topic-content">
                                <h4>Choose your Dear Government Lottery :</h4>
                                <p style={{ marginBottom: '0rem' }}>Pick the Dear Government Lottery you want to enter. Review key details from the administrator, including the total tickets available, ticket prices, and the percentage of winnings, to make an informed decision before participating.


</p>
                            </div>
                        </div>
                        <div className="topic-item">
                            <div className="icon-box">
                                <i className="fa fa-check"></i>
                            </div>
                            <div className="topic-content">
                                <h4>Place Bids :
</h4>
<p style={{ marginBottom: '0rem' }}>Place your bid by confirming your ticket purchase for the selected numbers. Carefully review your choices before finalising, as changes cannot be made after submission. Make sure you are confident in your selections.</p>
                            </div>
                        </div>
                        <div>
                       
                        </div>
                    </div>

                </div>
            </div>
            <div className="section-heading  text-center">
                        <a href="/appfeatures">
                            <h5 className="btn btn-main-2">Read More </h5>
                       </a>
                        </div>
        </section> 
        );
    }
}

export default Howtoplay;
