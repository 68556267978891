import React, { Component } from 'react';
// import {Link} from 'react-router-dom'

class Contact extends Component {
    render() {
        return (
                                    
            <section className="section-padding contact dark-bg-light">
                {/* <div className="container">
                    <div className="row justify-content-center mb-3">
                        <div className="col-lg-8">
                            <div className="section-heading center-heading text-center mb-60 ">
                                <h3 className="heading-title">Contact Us</h3>
                               
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center mt-2">
                        <div className="col-lg-10 col-sm-12 col-md-12">
                            <form className="contact__form form-row contact-form " method="post" action="#" id="contactForm" >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="alert alert-success contact__msg" style={{display:'none'}} role="alert">
                                            Your message was sent successfully.
                                        </div>
                                    </div>
                                </div>
                            
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" id="name" name="name" className="form-control" placeholder="First Name"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" id="age" name="age" className="form-control" placeholder="Last Name"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="phone" id="mobile" className="form-control" placeholder="Phone Number"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="email" id="email" className="form-control" placeholder=" Email Id"/>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea id="message" name="message" cols="30" rows="6" className="form-control" placeholder="Your Message"></textarea>    
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-12 center-heading">
                                        <div className="d-lg-flex justify-content-between mt-2">
                                           
                                            <input id="submit" name="submit" type="submit" className="btn btn-main-2" value="Send Message"/>
                                        </div>
                                    </div>
                                </div>
                            </form> 
                        </div>
                    </div>
                </div> */}
            </section>
        );
    }
}

export default Contact;
