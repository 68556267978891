import React, { Component } from 'react';

class FeatureRight extends Component {
    constructor(props){
        super(props);
        this.state = {
            collRight: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Bid and Draw Timings :',
                    text: 'Pay attention to the bid timings set by the administrator, as bids will only be accepted during this period. Additionally, keep an eye on the draw timings, as winners will be revealed at that scheduled time.'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Winning Range :',
                    text: 'The administrator will set a winning range among the total available numbers. If your chosen numbers fall within this range during the draw, congratulations—you’ll win the associated prize. Good luck!'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Claiming Prizes :',
                    text: 'Winners will be notified through the app. Follow the instructions to claim your prize, which will be credited directly to your wallet. Withdraw your winnings, enjoy your rewards, and celebrate your success! '
                },
                {
                    id: 4,
                    icon: 'fa fa-check',
                    title: 'Enjoy Responsibly :',
                    text: 'Lottery games are for entertainment, so please play responsibly. Establish limits on your participation and budget to ensure a fun and positive experience. Have fun, but always remember to play within your means.'
                },
                {
                    id: 5,
                    icon: 'fa fa-check',
                    title: 'Stay Informed :',
                    text: 'Stay updated with app notifications and announcements for upcoming Dear Government Lottery draws, and promotions. Keep an eye on the alerts to ensure you don’t miss exciting opportunities and enhance your lottery experience! '
                }
            ]
        }
        this.observer = null;
        this.topicItemsRef = React.createRef();
    }

    componentDidMount() {
        this.observer = new IntersectionObserver(
            entries => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('in-view');
                    }
                });
            },
            {
                threshold: 0.1
            }
        );
        
        const topicItems = this.topicItemsRef.current;
        if (topicItems) {
            this.observer.observe(topicItems);
        }
    }

    componentWillUnmount() {
        if (this.observer) {
            this.observer.disconnect();
        }
    }

    render() {
        return (
            <div className="topic-items" ref={this.topicItemsRef}>
                <style>
        {`
        p {
    margin-top: 0;
    margin-bottom: 0rem;
}
        `}
      </style> 
                {
                    this.state.collRight.map((data,i) => (
                        <div className="topic-item" key={i}>
                        <div className="icon-box">
                            <i className={data.icon}></i>
                        </div>
                        <div className="topic-content">
                            <h4>{data.title}</h4>
                            <p>{data.text}</p>
                        </div>
                    </div>
                    ))
                }
            </div>
        );
    }
}

export default FeatureRight;
