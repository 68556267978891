import React from "react";
import { Header, Contact, Client, Footer } from "../layouts/home04/index";

const mapFile = process.env.REACT_APP_MAP_URL;

function Contactus() {
  return (
    <div className="dark-theme">
      <style>
        {`
     .dark-bg p, .dark-bg-light p {
     text-align: start;
}
    `}
      </style>
      <Header />

      <section className="section-padding contact dark-bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading center-heading text-center mb-60">
                <h3 className="heading-title text-center">Get in touch</h3>
              </div>
            </div>
          </div>
          <div className="row justify-content-center section-padding">
            <div className="col-lg-4 col-sm-12 col-md-12 mails">
              <div className="colorr feature-style-3 feature-style-2 mb-4 mb-lg-0">
                <h3 className="heading-title">Support :</h3>
                <a href="mailto:support@dearlotteries.app" className="font">
                  support@dearlotteries.app
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-sm-12 col-md-12 mails">
              <div className="colorr feature-style-3 feature-style-2 mb-4 mb-lg-0">
                <h3 className="heading-title">Grievance :</h3>
                <a href="mailto:grievance@dearlotteries.app" className="font">
                  grievance@dearlotteries.app
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <iframe
        title="Map"
        width="100%"
        height="450px"
        className="pt-4 mt-4"
        src={mapFile}
        style={{ border: 0 }}
        allowFullScreen
      ></iframe>
      <div className="section-padding footer pt-120 dark-bg mb-4">
        <div className="container">
          <div className="row"></div>
          <div className="row ">
            <div class="section-heading center-heading text-center mb-60">
              <h3 class="playlotteriors heading-title">Our Office Address </h3>
            </div>
            <div className="col-lg-3 me-auto col-sm-6 col-md-12">
              <div className="widget footer-widget mb-1 mb-lg-0">
                <h6> West Bengal :</h6>
                <p>
                  121 Jessore Road (South) Micheal Nagar,<br></br> Kolkata
                  700133
                </p>
                <p>Call: 1800 103 6711</p>
              </div>
            </div>

            <div className="col-lg-3 me-auto col-sm-6 col-md-12">
              <div className="widget footer-widget mb-1 mb-lg-0">
                <h6> Maharashtra :</h6>
                <p>
                  Business Point, Western Express Highway, Andheri East,
                  <br></br> Mumbai 400053
                </p>
                <p>Call: 99304 25378</p>
              </div>
            </div>
            <div className="col-lg-3 me-auto col-sm-6 col-md-12">
              <div className="widget footer-widget mb-1 mb-lg-0">
                <h6> Punjab :</h6>
                <p>
                  1322 Rajinder Nagar, Civil Lines,<br></br>
                  Ludhiana 141008
                </p>
                <p>Call: 94170 52976</p>
              </div>
            </div>
            <div className="col-lg-3 me-auto col-sm-6 col-md-12">
              <div className="widget footer-widget mb-1 mb-lg-0">
                <h6> Meghalaya :</h6>
                <p>8th Mile Baridua Ps, Khanapara Ri- Bhoi, Meghalaya.</p>
                <p>Call :81588 98611 </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Contactus;
