import React, { Component } from 'react';

class FeatureLeft extends Component {
    constructor(props){
        super(props);
        this.state = {
            collLeft: [
                {
                    id: 1,
                    icon: 'fa fa-check',
                    title: 'Introduction :',
                    text: 'Welcome to the Dear Government Lottery App, your gateway to an enhanced lottery experience! Your chance to win amazing prizes is just a tap away. Follow our easy steps to participate and prepare for the exhilarating draw. Good luck!'
                },
                {
                    id: 2,
                    icon: 'fa fa-check',
                    title: 'Explore Ongoing Dear Government Lotteries :',
                    text: 'Navigate through Dear Government Lottery app to access a range of available lotteries, each with unique features such as ticket availability, pricing, and prize information to enhance your experience and increase your chances of winning.'
                },
                {
                    id: 3,
                    icon: 'fa fa-check',
                    title: 'Choose your Dear Government Lottery :',
                    text: 'Select the Dear Goverment Lottery you want to participate in. Review the details provided by the administrator, including the total number of tickets available, ticket prices, and the winnings % '
                },
                {
                    id: 4,
                    icon: 'fa fa-check',
                    title: 'Ticket Selection :',
                    text: 'Choose one or more tickets from the available lot or buy tickets in series of 5, 10, 20, 50, or 100. Alternatively, use the app’s random selection feature to let it pick tickets for you.'
                },
                {
                    id: 5,
                    icon: 'fa fa-check',
                    title: 'Place Bids:',
                    text: 'Place your bid by confirming your ticket purchase for the selected numbers. Carefully review your choices before finalising, as changes cannot be made after submission. Make sure you are confident in your selections.'
                },
               
            ]
        }
    }

    render() {
        return (
            
            <div className="topic-items">
                   <style>
        {`
        p {
    margin-top: 0;
    margin-bottom: 0rem;
}
        `}
      </style> 
            {
                    this.state.collLeft.map((data,i) => (
                        <div className="topic-item" key={i}>
                            <div className="icon-box">
                                <i className={data.icon}></i>
                            </div>
                            <div className="topic-content">
                                <h4>{data.title}</h4>
                                <p>{data.text}</p>
                            </div>
                        </div>
                    
                    ))
            }
            </div>
        );
    }

}

export default FeatureLeft;
