import React from "react";
import { Header, Banner, Review, Footer } from "../layouts/home04/index";

function Winner() {
  return (
    <div className="dark-theme">
      <Header />
      <Banner />
      <section className="feature-2 section-padding dark-bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="section-heading center-heading text-center dark-bg-light">
                <h3 className="heading-title text-white">
                  {" "}
                  Dear Goverment Lottery Winners
                </h3>
                <p className="playlotteriors pt-2 mb-4">
                  {" "}
                  They say, “Fortune” has many names. For the ignorant, it is
                  “Unknown’. For the cynical, it is “Unlikely”. But for the
                  believer, it is “Possible”. Meet our wonderful winners who
                  believed in themselves, who believed in Dear Goverment
                  Lottery. May the gods of wealth keep blessing them. For life.
                </p>
              </div>
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-lg-3 col-md-6">
              <div className="padd15">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/oYlOM8t_NWw"
                  title="DEAR LOTTERIES | WINNERS | DEARLotteries"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="padd15">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/Wj5Rjjtp5so"
                  title="DEAR DIWALI BUMPER WINNER INTERVIEW VIDEO 14.11.2020 | DEARLotteries LIVE | DEAR LOTTERIES"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="padd15">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/Uh4Yyk9XUzQ"
                  title="Dear Lotteries - Punjab TVC"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="padd15">
                <iframe
                  width="100%"
                  height="auto"
                  src="https://www.youtube.com/embed/Wj5Rjjtp5so"
                  title="DEAR DIWALI BUMPER WINNER INTERVIEW VIDEO 14.11.2020 | DEARLotteries LIVE | DEAR LOTTERIES"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Review className="mb-4" />
      {/* <Author /> */}

      <div className="section-padding"></div>
      <Footer />
    </div>
  );
}

export default Winner;
