import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Chapter from './Chapter';
class About extends Component {
    constructor(props){
        super(props);
        this.state = {
            about: [
                {
                    id: 1,
                    icon: 'bi bi-badge2',
                    title: 'Turnover  2 Billion USD',
                    text: 'Play with over 20 Crore Dear Goverment Lottery users'
                },
                {
                    id: 2,
                    icon: 'bi bi-badge2',
                    title: 'Read On Any Device',
                    text: 'Invite your friends & collect big rewards'
                },
                {
                    id: 3,
                    icon: 'bi bi-badge2',
                    title: 'Dear Goverment Lottery',
                    text: 'Play more, earn more & get exciting rewards '
                }
            ]
        }
    }
    render() {
        return (
        <section className="about-section section-padding dark-bg-light">
           <div className="container mt-2">
           <div className="section-heading center-heading text-center mb-20">
                            <h3 className="heading-title text-white">Download the app and start winning.</h3>
                          
                        </div>
                <div className="row justify-content-center">
                <div className="col-lg-12 col-md-12">
                <div className="section-heading  center-heading text-center">
                               
                            <p className='lactcenter'>Dear Goverment Lottery is the first Lottery company in India to facilitate the Live telecast of the draws </p>
                        </div>
                        </div>
                        </div>
                        <div className="row">
                    <div className="col-lg-6 col-md-6">
                        {/* <div className="about-img pt-100 center-heading">
                            <img src="assets/images/banner/Group.png" alt="" className="about-imgs img-fluid"/>
                        </div> */}
                        <Chapter />
                    </div>
                    <div className="col-lg-6 col-md-6 abouttop30px ">
                    
                        {
                            this.state.about.map((data,i) => (
                                <div className="about-text-block" key={i}>
                                    <i className={data.icon}></i>
                                    <h4>{data.title}</h4>
                                    <p>{data.text} </p>
                                </div>
                            ))
                        }
                      
                      <div className="row mt-4 qrcode">
  <div className="col-lg-3 col-md-6  justify-content-center">
    <Link to="#" className='malft playstore malfts'>  
      <img src="assets/images/banner/qr.png" alt="" className="img-fluid"/>
    </Link> 
  </div>
  <div className="col-lg-6 col-md-12 mt-lg-4 mrem ">
    <div className="row ">
      <div className="text-center center-heading col-lg-12 col-md-12 d-flex playstoreflex jsp">
        <Link to="#" className='playstore mg43'>  
          <img src="assets/images/banner/d11_playstore.png" alt="" className="img-fluid"/>
        </Link> 
        <Link to="#"  className='playstore mj43'>
          <img src="assets/images/banner/d11_appstore.png" alt="" className="img-fluid"/>
        </Link>
      </div>
    </div>
    <p className="text-center mt-3 pppp malfts">
      Scan the QR Code to download the <br></br> Dear Government Lottery App
    </p>
  </div>
</div>


                    </div>
                </div>
            </div>
        </section>  
        );
    }
}

export default About;
