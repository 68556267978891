import React, { Component } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css/navigation';

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: [
                { id: 1, img: './assets/images/banner/mobileapp/SplashScreen.png', alt: 'Login' },
                { id: 2, img: './assets/images/banner/mobileapp/login.png', alt: 'Spin Win' },
                { id: 3, img: './assets/images/banner/mobileapp/Home.png', alt: 'Home' },
                { id: 4, img: './assets/images/banner/mobileapp/MyTickets.png', alt: 'Lottery Number Select' },
                { id: 5, img: './assets/images/banner/mobileapp/MyCart.png', alt: 'Winners' },
                { id: 6, img: './assets/images/banner/mobileapp/LotterynumSelect.png', alt: 'Ticket' },
                { id: 7, img: './assets/images/banner/mobileapp/LotterynumSelect-1.png', alt: 'My Cart' },
                { id: 8, img: './assets/images/banner/mobileapp/LotterynumSelect-2.png', alt: 'Winners' },
            ]
        };
    }

    render() {
        return (
            <section className="home2 screenshot padd20 dark-bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4"></div>
                        <div className="col-lg-4">
                            <div className="ss-slider-area wow fadeInUp" data-wow-delay="0.5s" data-wow-duration="1s">
                                <div className="swiper-container">
                                    <Swiper
                                        modules={[Autoplay, Navigation]}
                                        spaceBetween={20}
                                        slidesPerView={1}
                                        navigation={{
                                            nextEl: '.swiper-button-next',
                                            prevEl: '.swiper-button-prev',
                                        }}
                                        autoplay={{ delay: 3000 }}
                                    >
                                        {this.state.testimonial.map((data) => (
                                            <SwiperSlide key={data.id}>
                                                <div className="item">
                                                    <img src={data.img} alt={data.alt} />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                    <div className="swiper-button-next" style={{ bottom: '10px', right: '10px' }}></div>
                                    <div className="swiper-button-prev" style={{ bottom: '10px', left: '10px' }}></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Review;
