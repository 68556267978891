import React, { Component } from 'react';
import Slider from "react-slick";

class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testimonial: [
                {
                    id: 1,
                    img: './assets/images/banner/ATTARSINGH.png',
                    alt: 'images',
                    quoteText: 'Dear Christmas & New Year Bumper',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : 76465',
                    DrawDate: 'Draw Date :01-01-2022',
                    name: 'ATTAR SINGH',
                    designation: '5 CRORES',
                },
                {
                    id: 2,
                    img: './assets/images/banner/18102022095123.png',
                    alt: 'images',
                    quoteText: 'Dear Durga Puja Bumper Lottery',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : 44343',
                    DrawDate: 'Draw Date : 08-10-2022',
                    name: 'SUDIP MAITY',
                    designation: '5 CRORES',
                },
                {
                    id: 3,
                    img: './assets/images/banner/TALVINDER.png',
                    alt: 'images',
                    quoteText: 'Dear 500 Saturday Weekly Lottery',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : 255373',
                    DrawDate: 'Draw Date : 30-12-2023',
                    name: 'TALVINDER SINGH',
                    designation: '2.5 CRORE',
                },
               
                {
                    id: 4,
                    img: './assets/images/banner/NAVEENKUMAR.png',
                    alt: 'images',
                    quoteText: 'Dear 500 Saturday Weekly Lottery',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : 184798',
                    DrawDate: 'Draw Date : 06-01-2024',
                    name: 'NAVEEN KUMAR',
                    designation: '2.5 CRORE',
                },
                {
                    id: 5,
                    img: './assets/images/banner/BABAN-PAUL.png',
                    alt: 'images',
                    quoteText: 'Dear 500 Saturday Weekly Lottery',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : L 8806',
                    DrawDate: 'Draw Date : 17-02-2024',
                    name: 'BABAN PAUL',
                    designation: '2.5 CRORE',
                },
                
                {
                    id: 6,
                    img: './assets/images/banner/ratansaha.png',
                    alt: 'images',
                    quoteText: 'Dear 500 Monday Weekly  Lottery',
                    text: '1st Prize Winner',
                    PWTNumber: 'PWT Number : A 5032',
                    DrawDate: 'Draw Date : 11-05-2024',
                    name: 'RATAN SAHA',
                    designation: '2.5 CRORE',
                },
               
                
                
               
                
                
            ]
        }
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            centerMode: true,
            arrows: false,
            autoplay: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            centerPadding: '40px',
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                },
                
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: true
                    }
                }
            ]
        };
        return (
            <section className="testimonial section-padding dark-bg-light">
                <div className="container-fluid">
                    <div className="row justify-content-center mb-2">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-heading center-heading text-center">
                                <h3 className="heading-title text-white">Winner List</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-lg-12">
                            <div className="testimonials-slides">
                                <Slider {...settings}>
                                    {
                                        this.state.testimonial.map((data) => (
                                            <div className="review-item bg-img" key={data.id}>
                                                <h4 className=''>{data.text}</h4>
                                                <div className="client-desc">
                                                    <div className="quote-text">
                                                        <h6 className="">{data.quoteText}</h6>
                                                        <h6 className=''>{data.PWTNumber}</h6>
                                                        <h6 className=''>{data.DrawDate}</h6>
                                                    </div>
                                                </div>
                                                <div className='row'>
      <div className='col-lg-8 client-text'>
        {/* <div className="d-flex align-items-end ddcolum"> */}
          <h6>{data.name}</h6>
          <h2 className="designation">{data.designation}</h2>
        {/* </div> */}
      </div>
      <div className='col-lg-4'>
        <div className="align-items-left">
          <img src={data.img} alt={data.alt} className='imgfloatright' />
        </div>
      </div>
    </div>
                                            </div>
                                        ))
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Review;
