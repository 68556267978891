import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Scroll to top when the pathname changes
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentPath]);

    return (
        <header className="main-header">
            <div className="site-navigation main_menu" id="mainmenu-area">
                <nav className="navbar navbar-expand-lg">
                    <div className="container">
                        <Link to="/" className="navbar-brand">
                            <img src="assets/images/logo.png" alt="DearLottery" className="img-fluid" style={{ width: '160px' }}/>
                        </Link>

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="fa fa-bars navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarMenu">
                            <ul className="navbar-nav me-auto">
                                <li className="nav-item">
                                    <Link to="/" className={`nav-link ${currentPath === '/' ? 'active' : ''}`}>Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/winner" className={`nav-link ${currentPath === '/winner' ? 'active' : ''}`}>Winners</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/ticketsviews" className={`nav-link ${currentPath === '/ticketsviews' ? 'active' : ''}`}>Tickets</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/appfeatures" className={`nav-link ${currentPath === '/appfeatures' ? 'active' : ''}`}>How to Play</Link>
                                </li>
                                {/* <li className="nav-item">
                                    <Link to="/aboutus" className={`nav-link ${currentPath === '/aboutus' ? 'active' : ''}`}>About Us</Link>
                                </li> */}
                                <li className="nav-item">
                                    <Link to="/contactus" className={`nav-link ${currentPath === '/contactus' ? 'active' : ''}`}>Contact Us</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="header-right-info d-none d-lg-block">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <Link to="/" className="playstore">
                                        <img src="assets/images/banner/d11_appstore.png" alt="" className="img-fluid"/>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/" className="playstore">
                                        <img src="assets/images/banner/d11_playstore.png" alt="" className="img-fluid"/>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
}

export default Header;
