import React from "react";
import {
  Header,
  Video,
  Author,
  About,
  Howtoplay,
  Feature2,
  Review,
  Faq,
  Contact,
  Client,
  Footer,
} from "../layouts/home04/index";

function LandingPage() {
  return (
    <div className="dark-theme">
      <Header />
      <Video />
      <About />
      <Feature2 />
      <Howtoplay />
      <Author />
      <Review />
      <Faq />
      <Contact />
      <Client />
      <Footer />
    </div>
  );
}

export default LandingPage;
