import React from "react";
import { Header, Footer } from "../layouts/home04/index";

const data = [
  {
    id: 1,
    personalData: "Full name",
    purpose: "For identification process.",
    legalGround:
      "To fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 2,
    personalData: "Address",
    purpose: "For identification process.",
    legalGround:
      "To fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 3,
    personalData: "Mobile number",
    purpose:
      "For identification and verification process. Sending of marketing information – if we have your consent.",
    legalGround:
      "To fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 4,
    personalData: "Email address",
    purpose:
      "For identification and communication purposes. We also use your email for sending of marketing information – if we have your consent.",
    legalGround:
      "To communicate with the customer and for sending messages. To fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 5,
    personalData: "Date of Birth",
    purpose: "For identification process.",
    legalGround:
      "To identify the customer and fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 6,
    personalData: "IP address",
    purpose:
      "To verify which location, you as a customer is connecting from. It is further used for preventing fraud and improper use of our systems of different types.",
    legalGround:
      "To fulfill the contractual agreement between the company and the customer. Also used for complying with regulatory and legal responsibilities.",
  },
  {
    id: 7,
    personalData: "Identity verification documents (Aadhaar and PAN details)",
    purpose: "For identification process.",
    legalGround:
      "To identify the customer and also to fulfill the contractual agreement between the company and the customer. Also used for complying with regulatory and legal responsibilities.",
  },
  {
    id: 8,
    personalData:
      "Bank Account, credit and debit cards, net banking, UPI details and other payment formats, transaction history, regarding deposits, withdrawals, and in game results connected to your account.",
    purpose:
      "For book keeping/ financial records, anti-money laundering reporting, counter terrorist financing reporting and other financial legislation. Also used for processing your game play and transactions including your use of credit cards and on-line payment systems.",
    legalGround:
      "The legal basis for processing this data is to uphold the company’s legal requirements according to external legislation such as Prevention of Money-Laundering Act, 2002 combatting money laundering and terrorist financing. Further, it is used for book keeping as required by law.",
  },
  {
    id: 9,
    personalData:
      "Other type of information that you provide to us by filling in forms on the website email or web chat or telephone or via mobile application.",
    purpose:
      "Providing customer support for our products and services. Used for answering your questions and offering support within the contractual agreement.",
    legalGround:
      "To fulfill the contractual agreement between the company and the customer.",
  },
  {
    id: 10,
    personalData:
      "Your responses to surveys or customer research that we carry out from time to time, Details of the transactions you carry out with us, whether via the Website or other means of communication.",
    purpose: "For improving our services and offer",
    legalGround:
      "This data is used in compliance with legitimate interests of the company, such as but not limited to providing the services expected.",
  },
  {
    id: 11,
    personalData:
      "Gaming behavior and use of the site, transaction history and personal pre-requisites related to the use of our services",
    purpose: "For improving our services and offer",
    legalGround:
      "This data is used in compliance with legitimate interests of the company, such as but not limited to providing the services expected.",
  },
];

function PrivacyPolicy() {
  return (
    <div className="dark-theme">
      <Header />

      <section className="section-padding contact dark-bg-light">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-heading center-heading text-center mb-60">
                <h3 className="heading-title">Privacy Policy </h3>
                <p>Dear Government Lottery - Version 1.1, 8 th March 2024</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-sm-12 col-md-12">
              <ul>
                <li>
                  This Privacy Policy explains how we collect, protect and use
                  the personal information. We are committed to ensure that any
                  personal information supplied by our customers or otherwise
                  generated by our business activities is collected and
                  processed fairly and lawfully.
                </li>
                <h6>SUMMARY</h6>
                <li>
                  We will use the personal data as stated in this policy, which
                  shall include but not limited to; processing of your personal
                  data in order to provide by approving our terms and conditions
                  for using our services, you agree you with more specific
                  marketing material or if applicable excluding you from
                  receiving marketing material or blocking you from our services
                  due to self-exclusion or for other reasons mentioned in the
                  Terms and Conditions.
                </li>
                <li>
                  We will store your personal data for purposes such as
                  financial records, transaction data for prevention of fraud
                  and other suspicious activities. We will report the relevant
                  authorities if there is a legal requirement for us to do so.
                </li>
                <li>
                  We will also share your data with service providers to fulfill
                  our contractual agreement. This Policy is a part of our Terms
                  and conditions when you use our services. Kindly read them in
                  detail to understand the policy.
                </li>

                <li>
                  By using this application, you agree to our Privacy Policy.
                </li>
              </ul>
              <h6>1. ABOUT US </h6>
              <ul>
                <li>
                  References to “we”, “us” and “our” shall mean any (our company
                  name) company or associated entity of the Charles Group. The
                  Charles Technologies Private Limited development unit develops
                  fintech applications and online gaming applications. Whereas
                  “Charles Technologies Private Limited” is a registered company
                  (CIN number: U62013TN2024PTC170602) registered with the
                  Ministry of Corporate Affairs, India. The Registered Address
                  is No 169, Habibullah Road, T. Nagar, Chennai – 600017. The
                  company is licensed and regulated by (Name of the authority)
                  with license number (…………..), that was assigned to the company
                  on (………) and to be renewed on (………..). References to “you” and
                  “your” shall refer to the customer or any person using our
                  Website or mobile applications and providing personal
                  information to us.
                </li>

                <h6>2. PURPOSE</h6>
                <li>
                  We are committed to adhering with the applicable laws in
                  handling of personal data in order to protect and process your
                  personal data in a compliant manner. We are compliant to The
                  Information Technology (reasonable Security Practices and
                  Procedures and Sensitive Personal Data or Information) Rules,
                  2011 or “SPDI Rules” in short notified by the Ministry of
                  Electronics and Information Technology, Government of India.
                  The online lottery services offered by us are in compliance to
                  respective state laws that permit online lottery. This Privacy
                  Policy specifies the way in which we deal with the information
                  and data you provide to us to in the provision of our
                  services. We will process any personal information provided to
                  us or otherwise held by us relating to you in the manner set
                  out in this Privacy Policy. Information may be provided via
                  the www.dearlotteries.app website (the "Website"), email,
                  mobile application, telephone calls, live chat or by any other
                  means. By submitting your information to us and using our
                  Website/ Mobile Platform you confirm your consent to our use
                  of your personal data as set out in this policy. If you do not
                  agree with the terms of this Privacy Policy, you may not use
                  the Website/ Platform or our services.
                </li>

                <h6>3. PERSONAL DATA COLLECTED AND PROCESSED </h6>
                <li>
                  We collect the following data from you. If you choose not to
                  provide data, then we cannot handle your account or provide
                  the services to you. Your personal information is necessary to
                  deliver our Services to you:
                </li>
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Sl.No.</th>
                      <th>Personal data used</th>
                      <th>Purpose</th>
                      <th>Legal ground / Legitimate interests</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((row) => (
                      <tr key={row.id}>
                        <td>{row.id}</td>
                        <td>{row.personalData}</td>
                        <td>{row.purpose}</td>
                        <td>{row.legalGround}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <h6>4. DATA GATHERING </h6>
                <li>
                  In order to be compliant with the relevant Responsible Gaming
                  obligations and also to provide the most relevant offers, we
                  also use providers such as KYC Verification using API
                  Services, SMS-Airtel (OTP), Razorpay, CC Avenue (Payment
                  gateway). We also use the data in some cases exclude our
                  customers from marketing if needed due to self-exclusion
                  purposes. We also may block some customers from using our
                  services if they are legally permitted to use our services or
                  if they voluntarily chose to self-exclude themselves from our
                  services.
                </li>
                <h6>5. DATA RETENTION </h6>
                <li>
                  Your data will be retained as long as you remain our customer.
                  Unless you expressly in writing intimate us regarding deletion
                  of your data, your data shall remain with us. If you cease to
                  be our customer, then we will keep your data either by seeking
                  your permission or till such term the applicable law mandates.
                </li>
                <h6>6. THIRD PARTY REFERALS </h6>
                <li>
                  We could offer different types of bonuses in relation to third
                  party referrals. Those bonuses could be based on a situation
                  where you as a customer to us could refer a friend of yours to
                  us. If this would in any type be applicable and if you give us
                  information about someone else, you must seek approval from
                  that person for referring the person to us before you do so.
                  If we would send out a referral link that you as a customer
                  could send to a friend and then receive a referral bonus for,
                  you must also seek that person’s approval before doing so. You
                  shall inform the referred person on our Privacy Policy when
                  entering into a customer relationship with us and the data
                  belonging to that person will be handled in accordance with
                  this Privacy Policy as well as the applicable law.
                </li>
                <h6>7. YOUR RIGHTS </h6>
                <li>
                  As mandated under the law, you shall have the right to access,
                  rectify and erase your personal data with us or delete your
                  entire account also. In case of deletion of account, all data
                  available with us will be deleted in the account. Any data
                  which has been shared with our service provider will be also
                  be processed for deletion. However, if any data is already
                  processed by our service providers, then the future processing
                  of your data will be permanently stopped. You shall obtain
                  expert legal advice on your own on how to protect your data.
                </li>
                <li>
                  Your rights are subject to applicable law. As such if there is
                  any legal obligation that requires us to retain, keep or
                  handle or share your personal data, then we shall comply with
                  the applicable law and the directions of the authorities.
                </li>
                <li>
                  You have the right to access and rectify your personal
                  information we have collected. This right includes:
                </li>
                <li>
                  Confirmation of the accuracy of the personal data we have
                  collected,
                </li>
                <li>
                  <i class="fa fa-circle"></i> Request the deletion or
                  rectification of personal data that is inaccurate
                </li>
                <li>
                  <i class="fa fa-circle"></i> Provide details as to the use of
                  your personal information,
                </li>
                <li>
                  <i class="fa fa-circle"></i> Prohibit future use of your data
                  for marketing purposes.{" "}
                </li>
                <li>
                  If you wish to contact us regarding your personal information,
                  please send an email to
                  <a href="mailto:support@dearlotteries.app">
                    {" "}
                    support@dearlotteries.app.{" "}
                  </a>{" "}
                  Please refer to our T&C for contact information.
                </li>

                <h6>8. AUTOMATED DECISION MAKING </h6>
                <li>
                  The purpose and legal grounds for processing your data are
                  mentioned below:{" "}
                </li>
                <li>
                  8.1 Risk behavior / Responsible Gaming: We are legally bound
                  by law to monitor our players from a risk perspective related
                  to responsible gaming. In order to identify players who shows
                  risk behavior and contact these players with support or help
                  in accordance with our Responsible Gaming obligations. We
                  monitor gameplay, deposits and other transactions to be able
                  to identify these players.
                </li>
                <li>
                  8.2 Risk behavior/ AML & CFT: We are legally obliged to take
                  actions based on the requests or directions of the regulatory
                  authorities against money laundering and financing of
                  terrorist activities. Therefore, users’ behavior is monitored
                  for these purposes when using our services. The identification
                  of personal data, transaction behavior and other relevant
                  factors such as the region the user is connected to and is
                  actively present, type of player etc. are being processed and
                  monitored.
                </li>
                <h6>9. MARKETING</h6>
                <li>
                  We intend to provide the best possible experience to the
                  users. Hence, we always strive to improve our services to you.
                  Therefore, we try to contact you, from time to time and only
                  with your consent, with campaigns based on your pre-requisites
                  and behavior on our site. This could include your behavior
                  such as signing up but not depositing, signing up and
                  depositing or depositing only once. In those cases, we could
                  come to contact you with relevant offers to make your signing
                  process smoother. If you are not interested in our marketing
                  communications, please send an email to
                  <a href="mailto:info@dearlotteries.app">
                    {" "}
                    info@dearlotteries.app
                  </a>
                </li>
                <h6>10.INFORMATION SHARING AND DISCLOSURE </h6>
                <li>
                  We may share your Personal Data with the following agencies:{" "}
                </li>
                <li>
                  <i class="fa fa-circle"></i> Law enforcement agencies, courts,
                  regulators and other authorities.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Fraud prevention agencies.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Identity verification agencies.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Payment processing agencies.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Individuals and Organizations
                  that introduce you to us.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Third parties / referrals you ask
                  us (or permit us) to share your data with.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Marketing service providers.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Service providers of technical
                  communication in order to communicate with you.
                </li>
                <li>
                  <i class="fa fa-circle"></i> Other organizations that help us
                  understand and process data in a secure and standardized
                  manner.
                </li>
                <li>
                  <i class="fa fa-circle"></i> To any third party which
                  approaches us with the order of a competent court
                </li>
                <li>
                  Data Processing of your information may be undertaken by any
                  company within the territorial limits of Indian subcontinent,
                  which may use a third party to fulfill such data processing
                  needs. In case, where your Personal Data is transferred
                  outside of India, we shall take all reasonable steps to ensure
                  that your information and data is treated securely and in
                  accordance with this Privacy Policy as well as complies with
                  all legal requirements associated with such a transfer. We do
                  not sell personal data to third parties. However, your
                  personal data may be transferred if our company is acquired by
                  a third party, merged with a third party or if we
                  sell/transfer all or substantially all of its assets, or
                  otherwise to a third party, associate entities or sister
                  concerns. In this event, we will inform you prior to affecting
                  such transfer of the personal data.
                </li>
                <h6>11. CHANGES TO THIS PRIVACY POLICY </h6>
                <li>
                  The terms of this policy will be updated from time to time and
                  we will notify changes to you by posting the modified terms on
                  the Website. You are recommended to revisit this Privacy
                  Policy regularly. The earlier versions of this policy will be
                  stored by us and you are free to request for a copy of the
                  same.{" "}
                </li>
                <h6>12. SENSITIVE DATA AND DATA OF CHILDREN AND MINORS </h6>
                <li>
                  Sensitive personal data such as information on health, ethnic
                  origin, political views etc. are not stored by us. However, we
                  might have to store sensitive personal data related to our
                  responsibilities in accordance with Responsible Gaming
                  obligations. For the sake of clarity, if you applied for
                  selfexclusion due to gambling addiction or likewise, we will
                  store that information in order for blocking you from our
                  services or marketing campaigns, we might still contact you
                  with other purposes, such as support for withdrawal of funds
                  or likewise. Minors or children are not permitted by us, to
                  use our services and we do not store any of their personal
                  data.
                </li>
                <h6>13. USE OF COOKIES </h6>
                <li>
                  By using our website & App and registering an account, you
                  acknowledge that we use cookies.
                </li>
                <li>15.1 What is a Cookie?</li>
                <li>
                  {" "}
                  Cookies are small text files containing information which are
                  sent to and stored on your computer, smartphone or other
                  device when you access a website & App. Cookies are then sent
                  back to the originating website & App or platform on each
                  subsequent visit or to another website & App that recognizes
                  that cookie. Cookies are useful because they allow a website &
                  App and platform to recognize a user's device.
                </li>
                <li>
                  15.2 Cookies are used on the website & App and mobile
                  platform: Cookies are used to collect information including
                  the type of browser you use and your IP number. This
                  information is collected for security reasons and with the aim
                  of constantly improving our service to better meet your needs.
                  We have taken all possible efforts for no unnecessary use of
                  cookies.
                </li>
                <li>
                  15.3 Managing Cookies: To delete any cookies already stored on
                  your computer or stop the cookies that keep track of your
                  browsing patterns on the website & App, you can do so by
                  deleting your existing cookies and/or altering your browser's
                  privacy settings (the process you follow will differ from
                  browser to browser).
                </li>
                <h6>14. DATA SECURITY: </h6>
                <li>
                  We prioritize data security with robust safeguard mechanism
                  and breach notification protocols. We comply with all relevant
                  data protection laws, SPDI Rules including Digital Personnel
                  Data Protection Act, 2023. We ensure customer data is
                  protected to the highest standards and in line with government
                  regulations.
                </li>
                <h6>15. LODGE A COMPLAINT OR GRIEVANCE</h6>
                <li>
                  If you want to lodge a complaint or grievance, you shall send
                  an email to
                  <a href="mailto:grievance@dearlotteries.app">
                    {" "}
                    grievance@dearlotteries.app{" "}
                  </a>{" "}
                </li>
                <li> Grievance Officer: Mr Shree Vishnu Thangamuthu</li>
                <li>
                  {" "}
                  Phone: <a href="tel:8489925481"> +91 8489925481 </a>
                </li>
                <h6>16. CONTACT US </h6>
                <li>
                  Please contact us regarding your personal data by asking
                  questions regarding the use or storage or processing of your
                  data, by emailing us on{" "}
                  <a href="mailto:support@dearlotteries.app">
                    {" "}
                    support@dearlotteries.app
                  </a>{" "}
                  . You can also get in touch with us through our customer
                  service (info@dearlotteries.app).
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
