import React, { Component } from 'react';
import Slider from 'react-slick';  // Importing the slider
import 'slick-carousel/slick/slick.css';  // Required CSS for slick
import 'slick-carousel/slick/slick-theme.css';  // Optional theme

class Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
        
            images: [
                'assets/images/banner/winner01.png',
            ],
        };
    }

    render() {
        const sliderSettings = {
            dots: true,
            infinite: true,
            autoplay: true,  // Enable autoplay
            autoplaySpeed: 5000,  // Time between slides (longer interval)
            speed: 2000,  // Slow transition speed
            slidesToShow: 1,  // One slide at a time
            slidesToScroll: 1,  // Scroll one at a time
        };

        return (
            
            <section className="background-image">
                 <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                       
                     
                        <div className="col-lg-12 fade-in">
                 <Slider {...sliderSettings}>
                                {this.state.images.map((image, index) => (
                                    
                                        <img src={image} alt="" className="img-fluid" />

                                ))}
                            </Slider>
                            </div>
                         
                            </div>
                </div>
                {/* <div className="container">
                    <div className="row align-items-center justify-content-between">
                       
                        <div className="col-lg-4 fade-in">
                           
                        </div>

                        <div className="col-md-12 col-lg-4">
                            <div className="banner-content">
                             
                               
                               
                                <div className="cta-content">
                    
                           
                         
                          
                        <Link to="#" className='playstore'>  
                        <img src="assets/images/banner/d11_playstore.png" alt="" className="img-fluid"/>
                        </Link> 
                       
                        <Link to="#"  className='playstore'>
                        <img src="assets/images/banner/d11_appstore.png" alt="" className="img-fluid"/>
                       </Link>
                      
                                
                            
                        </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 fade-in">
                           
                        </div>
                    </div>
                </div> */}
            </section>
        );
    }
}

export default Banner;
